import { SVG } from '@/assets/svg'
import style from './style.module.scss'
import { getTtLinksByLocale } from '@/router/links'
import { useLanguageContext } from '@/providers/LanguageProvider'

export const SocialLink = () => {
  const { locale } = useLanguageContext()

  const socialList = [
    {
      id: 1,
      icon: <SVG.Social.Telegram />,
      href: getTtLinksByLocale('telegram', locale),
    },
    {
      id: 2,
      icon: <SVG.Social.Discord />,
      href: getTtLinksByLocale('discord', locale),
    },
    {
      id: 3,
      icon: <SVG.Social.Youtube />,
      href: getTtLinksByLocale('youtube', locale),
    },
  ]

  return (
    <div className={style.wrapper}>
      <span className={style.title}>Социальные сети ScalpSecta</span>
      <div className={style.content}>
        {socialList.map((social: any) => {
          return (
            <a className={style.socialButton} href={social.href} target="_blank" rel="noreferrer" key={social.id}>
              {social.icon}
            </a>
          )
        })}
      </div>
    </div>
  )
}
