import styles from './style.module.scss'
import { LeaderCard } from './LeaderCard/LeaderCard'
import { TitleH4 } from '@/ui/atoms/TitleH4'
import { t } from '@lingui/macro'

const leaderboardData = [
  {
    position: 2,
    username: 'beautifulbutterfly101',
    amount: '+324,420,000.32',
    currency: 'PnL, USDT',
  },
  {
    position: 1,
    username: 'beautifulbutterfly101',
    amount: '+324,420,000.32',
    currency: 'PnL, USDT',
  },
  {
    position: 3,
    username: 'beautifulbutterfly101',
    amount: '+324,420,000.32',
    currency: 'PnL, USDT',
  },
]

export const TotalLeaders = () => {
  return (
    <div className={styles.leaderboard}>
      <TitleH4
        label={t({
          id: 'leaderBoard.totalLeader.title',
          comment: 'Absolute leaders of the week',
        })}
      />
      <p className={styles.subHeader}>PnL в рамках всех бирж</p>
      <div className={styles.cardsContainer}>
        {leaderboardData.map(item => (
          <LeaderCard
            key={item.position}
            position={item.position}
            username={item.username}
            amount={item.amount}
            currency={item.currency}
          />
        ))}
      </div>
    </div>
  )
}
