import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import { SVG } from '@/assets/svg'
import gold from '@/assets/png/avatar-gold.png'
import silver from '@/assets/png/avatar-silver.png'
import bronze from '@/assets/png/avatar-bronze.png'

type Props = {
  position: number
  username: string
  amount: string
  currency: string
}

export const LeaderCard: React.FC<Props> = (props: Props) => {
  const getMedalIcon = (position: number) => {
    switch (position) {
      case 1:
        return (
          <div className={styles.firstPosition}>
            <SVG.LeaderBoard.FirstPlace height="60" width="51" />
          </div>
        )
      case 2:
        return (
          <div className={styles.secondPosition}>
            <SVG.LeaderBoard.SecondPlace height="44" width="44" />
          </div>
        )
      case 3:
        return (
          <div className={styles.thirdPosition}>
            <SVG.LeaderBoard.ThirdPlace height="40" width="40" />
          </div>
        )
      default:
        return null
    }
  }

  const getAvatarIcon = (position: number) => {
    switch (position) {
      case 1:
        return <img src={gold} alt="first place" />
      case 2:
        return <img src={silver} alt="second place" />
      case 3:
        return <img src={bronze} alt="third place" />
      default:
        return null
    }
  }

  const cardStyle = clsx({
    [styles.first]: props.position === 1,
    [styles.second]: props.position === 2,
    [styles.third]: props.position === 3,
  })

  return (
    <div className={clsx(styles.card, cardStyle)}>
      <div className={styles.test}></div>
      <div className={styles.position}>{getMedalIcon(props.position)}</div>
      <div>{getAvatarIcon(props.position)}</div>
      <div className={styles.username}>{props.username}</div>
      <div className={styles.currency}>{props.currency}</div>
      <div className={styles.amount}>{props.amount}</div>
    </div>
  )
}
