import { useEffect, useState } from 'react'
import style from './style.module.scss'
import { PersonalResults } from './components/PersonalResults/PersonalResults'
import { TotalLeaders } from './components/TotalLeaders/TotalLeaders'
import { LeaderList } from './components/LeaderList/LeaderList'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { t } from '@lingui/macro'
import { SocialLink } from './components/SocialLink/SocialLink'
import { Dropdown } from '@/ui/kit'

const TYPE_INTERVAL_OPTIONS = [
  {
    value: 'DAY',
    label: 'За день',
  },
  {
    value: 'WEEK',
    label: 'За неделю',
  },
  {
    value: 'MONTH',
    label: 'За месяц',
  },
]

export const LeaderBoard = () => {
  const [selectedInterval, setSelectedInterval] = useState<any>(TYPE_INTERVAL_OPTIONS[0])

  useEffect(() => {}, [])

  const teamName = 'test'
  const title = t({
    id: 'leaderBoard.page.title',
    comment: 'Team Leaderboard',
  })

  const handleChangeType = (option: { value: any; label: string }) => {
    setSelectedInterval(option)
  }

  return (
    <div className={style.wrapper}>
      <div className={style.headerWrapp}>
        <div className={style.header}>
          <TitleH3 label={`${title} ${teamName}`} />
          <span className={style.header__description}>
            {t({
              id: 'leaderBoard.page.description',
              comment: 'The results are displayed for the currently selected exchanges',
            })}
          </span>
        </div>
        <div className={style.filter}>
          {t({
            id: 'leaderBoard.page.filter.showResults',
            comment: 'Show Results',
          })}
          <Dropdown
            options={TYPE_INTERVAL_OPTIONS}
            selectedOption={selectedInterval}
            setSelectedOption={handleChangeType}
            size="medium"
          />
        </div>
      </div>
      <div className={style.content}>
        <div className={style.resultWrapp}>
          <PersonalResults />
          <TotalLeaders />
        </div>
        <div className={style.leaderLists}>
          <LeaderList name="Spot" />
          <LeaderList name="Futures" />
          <LeaderList name="Pnl" />
        </div>
      </div>
      <div className={style.linkWrapp}>
        <SocialLink />
      </div>
    </div>
  )
}
