import styles from './style.module.scss'
import { ResultCard } from './ResultCard/ResultCard'
import { t } from '@lingui/macro'
import { TitleH4 } from '@/ui/atoms/TitleH4'

export const PersonalResults = () => {
  return (
    <div className={styles.wrapper}>
      <TitleH4
        className={styles.header}
        label={t({
          id: 'leaderBoard.personalResults.title',
          comment: 'Your results of the week',
        })}
      />
      <ResultCard position={2} title="Spot" amount="987,654.32" currency="USDT" />
      <ResultCard position={1} title="Feature" amount="324,420,000.32" currency="USDT" />
      <ResultCard position={3} title="PnL" amount="+8,321,420.00" currency="USDT" />
    </div>
  )
}
