import { FC } from 'react'

export const Cup: FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.1499 24.5V26.6" stroke="#FAFAFA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M15.1499 30H25.1499V29C25.1499 27.9 24.2499 27 23.1499 27H17.1499C16.0499 27 15.1499 27.9 15.1499 29V30V30Z"
      stroke="#FAFAFA"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path d="M14.1499 30H26.1499" stroke="#FAFAFA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M20 24C16.13 24 13 20.87 13 17V14C13 11.79 14.79 10 17 10H23C25.21 10 27 11.79 27 14V17C27 20.87 23.87 24 20 24Z"
      stroke="#FAFAFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.47 19.65C12.72 19.41 12.06 18.97 11.54 18.45C10.64 17.45 10.04 16.25 10.04 14.85C10.04 13.45 11.14 12.35 12.54 12.35H13.19C12.99 12.81 12.89 13.32 12.89 13.85V16.85C12.89 17.85 13.1 18.79 13.47 19.65Z"
      stroke="#FAFAFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.53 19.65C27.28 19.41 27.94 18.97 28.46 18.45C29.36 17.45 29.96 16.25 29.96 14.85C29.96 13.45 28.86 12.35 27.46 12.35H26.81C27.01 12.81 27.11 13.32 27.11 13.85V16.85C27.11 17.85 26.9 18.79 26.53 19.65Z"
      stroke="#FAFAFA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
