import { t } from '@lingui/macro'
import { enqueueSnackbar } from 'notistack'
import { FC } from 'react'
import { referralLinkCreator, urls } from '@/router/urls'
import { Button, ButtonSize, CopyIcon } from '@/ui/kit'
import { copyToClipboard } from '@/utils'
import { useTeamData } from '@/utils/hooks/useTeamData'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import s from './style.module.scss'
import { useNavigate } from 'react-router-dom'

const Team: FC = () => {
  const {
    userData: { teamManagerInfo },
  } = useTeamData()

  const { gainByMonth, numberOfMembers, teamCode, referralCode } = teamManagerInfo
  const navigate = useNavigate()

  const handleCopyLink = () => {
    copyToClipboard(referralLinkCreator(encodeURIComponent(referralCode)))

    enqueueSnackbar(t({ id: 'teamPage.linkCopyMessage', message: 'Referral link copied' }), {
      variant: 'success',
    })
  }

  function navigateToLeaderBoard() {
    navigate(urls.leaderBoard)
  }

  return (
    <div className={s.root}>
      <div className={s.membersCount}>{t({ id: 'teamPage.count', message: 'Count of members' })}</div>
      <span className={s.members}>{numberOfMembers}</span>
      {gainByMonth > 0 && (
        <span className={s.count}>
          +{gainByMonth} {t({ id: 'teamPage.month', message: 'this month' })}
        </span>
      )}
      {/* DISABLED LEADERBOARD */}
      {/* <div className={s.btnWrapp}>
        <Button.Accent
          label={t({
            id: 'teamPage.button.goToLeaderBoard',
            comment: 'Go to Leader Board',
          })}
          onClick={navigateToLeaderBoard}
        />
      </div> */}
      <div className={s.codeBlock}>
        <div className={s.code}>
          {t({
            id: 'teamPage.code',
            message: 'Team Code',
          })}
        </div>
        <div className={s.refCode}>
          <span className={s.count}>{teamCode}</span>
          <CopyIcon disabled={!teamCode} text={teamCode} className={s.copy} />
        </div>
      </div>
      {referralCode && (
        <div className={s.referralContainer}>
          <div className={s.referral}>{t({ id: 'teamPage.url', message: 'Referral Url' })}</div>
          <Button.Accent
            size={ButtonSize.Medium}
            label={t({ id: 'core.button.copyLink', message: 'Copy link' })}
            className={s.button}
            onClick={handleCopyLink}
            dataTestId={DataTestIds.CopyUrlButton}
          />
        </div>
      )}
    </div>
  )
}

export { Team }
