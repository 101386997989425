import { FC } from 'react'

type Props = {
  width?: string
  height?: string
}

export const ThirdPlace: FC<Props> = ({ width = '40', height = '40' }) => (
  <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.48496 11.2675C8.57893 11.4708 9.40539 11.7359 10.2704 11.5009C11.1588 11.2597 12.0283 10.2865 12.0283 10.2865C12.0283 10.2865 10.7891 9.88601 9.90068 10.1272C9.79497 10.1561 9.69415 10.1915 9.59757 10.2311C9.6439 10.1354 9.68632 10.0351 9.72221 9.92927C10.0188 9.0538 9.70035 7.786 9.70035 7.786C9.70035 7.786 8.67812 8.59526 8.38121 9.4704C8.0993 10.3033 8.29344 11.1296 8.48496 11.2675Z"
      fill="black"
    />
    <path
      d="M8.48496 11.2675C8.57893 11.4708 9.40539 11.7359 10.2704 11.5009C11.1588 11.2597 12.0283 10.2865 12.0283 10.2865C12.0283 10.2865 10.7891 9.88601 9.90068 10.1272C9.79497 10.1561 9.69415 10.1915 9.59757 10.2311C9.6439 10.1354 9.68632 10.0351 9.72221 9.92927C10.0188 9.0538 9.70035 7.786 9.70035 7.786C9.70035 7.786 8.67812 8.59526 8.38121 9.4704C8.0993 10.3033 8.29344 11.1296 8.48496 11.2675Z"
      fill="url(#paint0_linear_22160_170654)"
    />
    <path
      d="M12.7696 8.81617C13.6046 8.2806 14.185 7.00001 14.185 7.00001C14.185 7.00001 12.7843 6.98985 11.9494 7.52543C11.1144 8.061 10.7252 8.94794 10.8475 9.14034C10.9699 9.33273 11.9347 9.35142 12.7696 8.81617Z"
      fill="black"
    />
    <path
      d="M12.7696 8.81617C13.6046 8.2806 14.185 7.00001 14.185 7.00001C14.185 7.00001 12.7843 6.98985 11.9494 7.52543C11.1144 8.061 10.7252 8.94794 10.8475 9.14034C10.9699 9.33273 11.9347 9.35142 12.7696 8.81617Z"
      fill="url(#paint1_linear_22160_170654)"
    />
    <path
      d="M6.19711 14.5773C6.33708 14.7825 7.28948 14.9208 8.1936 14.5052C9.12218 14.0782 9.89677 12.8536 9.89677 12.8536C9.89677 12.8536 8.468 12.6425 7.53909 13.0696C7.42848 13.1204 7.32472 13.1781 7.22619 13.2391C7.25947 13.1257 7.28753 13.008 7.30776 12.8857C7.47318 11.8733 6.89436 10.5442 6.89436 10.5442C6.89436 10.5442 5.92238 11.6173 5.75696 12.6297C5.59937 13.5924 5.96219 14.4613 6.19711 14.5773Z"
      fill="black"
    />
    <path
      d="M6.19711 14.5773C6.33708 14.7825 7.28948 14.9208 8.1936 14.5052C9.12218 14.0782 9.89677 12.8536 9.89677 12.8536C9.89677 12.8536 8.468 12.6425 7.53909 13.0696C7.42848 13.1204 7.32472 13.1781 7.22619 13.2391C7.25947 13.1257 7.28753 13.008 7.30776 12.8857C7.47318 11.8733 6.89436 10.5442 6.89436 10.5442C6.89436 10.5442 5.92238 11.6173 5.75696 12.6297C5.59937 13.5924 5.96219 14.4613 6.19711 14.5773Z"
      fill="url(#paint2_linear_22160_170654)"
    />
    <path
      d="M4.88188 18.6112C5.10799 18.8023 6.24735 18.6558 7.15473 17.8875C8.08658 17.0982 8.58807 15.4417 8.58807 15.4417C8.58807 15.4417 6.87772 15.6577 5.94587 16.4469C5.83527 16.5403 5.73412 16.6393 5.64048 16.7406C5.64276 16.6003 5.63722 16.4561 5.62123 16.3096C5.48974 15.0923 4.40095 13.7497 4.40095 13.7497C4.40095 13.7497 3.62474 15.2958 3.75655 16.5131C3.88152 17.6708 4.57486 18.5535 4.88188 18.6112Z"
      fill="black"
    />
    <path
      d="M4.88188 18.6112C5.10799 18.8023 6.24735 18.6558 7.15473 17.8875C8.08658 17.0982 8.58807 15.4417 8.58807 15.4417C8.58807 15.4417 6.87772 15.6577 5.94587 16.4469C5.83527 16.5403 5.73412 16.6393 5.64048 16.7406C5.64276 16.6003 5.63722 16.4561 5.62123 16.3096C5.48974 15.0923 4.40095 13.7497 4.40095 13.7497C4.40095 13.7497 3.62474 15.2958 3.75655 16.5131C3.88152 17.6708 4.57486 18.5535 4.88188 18.6112Z"
      fill="url(#paint3_linear_22160_170654)"
    />
    <path
      d="M5.12627 22.5087C5.16216 22.4923 5.19642 22.4529 5.22741 22.3985C5.62384 22.349 6.46563 21.8639 6.99257 21.0098C7.61804 19.996 7.55507 18.3293 7.55507 18.3293C7.55507 18.3293 6.00819 19.0684 5.38272 20.0822C5.29756 20.2202 5.22643 20.3605 5.16509 20.5004C5.12724 20.3969 5.08548 20.2933 5.03523 20.19C4.51645 19.1241 3.05212 18.2477 3.05212 18.2477C3.05212 18.2477 2.81916 19.9006 3.33827 20.9668C3.85705 22.0327 4.87307 22.6244 5.12627 22.5087Z"
      fill="black"
    />
    <path
      d="M5.12627 22.5087C5.16216 22.4923 5.19642 22.4529 5.22741 22.3985C5.62384 22.349 6.46563 21.8639 6.99257 21.0098C7.61804 19.996 7.55507 18.3293 7.55507 18.3293C7.55507 18.3293 6.00819 19.0684 5.38272 20.0822C5.29756 20.2202 5.22643 20.3605 5.16509 20.5004C5.12724 20.3969 5.08548 20.2933 5.03523 20.19C4.51645 19.1241 3.05212 18.2477 3.05212 18.2477C3.05212 18.2477 2.81916 19.9006 3.33827 20.9668C3.85705 22.0327 4.87307 22.6244 5.12627 22.5087Z"
      fill="url(#paint4_linear_22160_170654)"
    />
    <path
      d="M6.70121 26.3419C6.72763 26.3147 6.7446 26.2668 6.75341 26.2059C7.09111 26.0272 7.67677 25.295 7.85231 24.3255C8.0608 23.1747 7.42652 21.6483 7.42652 21.6483C7.42652 21.6483 6.2976 22.8525 6.08944 24.0033C6.06105 24.16 6.04571 24.314 6.03952 24.4644C5.96969 24.3812 5.89628 24.2989 5.81569 24.2199C4.9814 23.4038 3.36665 23.0803 3.36665 23.0803C3.36665 23.0803 3.73143 24.6932 4.56539 25.5094C5.39969 26.3252 6.51458 26.5343 6.70121 26.3419Z"
      fill="black"
    />
    <path
      d="M6.70121 26.3419C6.72763 26.3147 6.7446 26.2668 6.75341 26.2059C7.09111 26.0272 7.67677 25.295 7.85231 24.3255C8.0608 23.1747 7.42652 21.6483 7.42652 21.6483C7.42652 21.6483 6.2976 22.8525 6.08944 24.0033C6.06105 24.16 6.04571 24.314 6.03952 24.4644C5.96969 24.3812 5.89628 24.2989 5.81569 24.2199C4.9814 23.4038 3.36665 23.0803 3.36665 23.0803C3.36665 23.0803 3.73143 24.6932 4.56539 25.5094C5.39969 26.3252 6.51458 26.5343 6.70121 26.3419Z"
      fill="url(#paint5_linear_22160_170654)"
    />
    <path
      d="M8.9088 29.1699C9.17635 28.9663 9.55058 28.2754 9.50099 27.4697C9.44226 26.5166 8.53978 25.4255 8.53978 25.4255C8.53978 25.4255 7.77694 26.5963 7.83567 27.5494C7.84383 27.6808 7.86471 27.807 7.89212 27.9293C7.80957 27.8752 7.72637 27.8214 7.63469 27.7733C6.70382 27.2842 5.17227 27.3347 5.17227 27.3347C5.17227 27.3347 5.8493 28.5458 6.78016 29.0348C7.71103 29.5239 8.76524 29.4777 8.89281 29.2895C8.91108 29.263 8.91402 29.2204 8.9088 29.1699Z"
      fill="black"
    />
    <path
      d="M8.9088 29.1699C9.17635 28.9663 9.55058 28.2754 9.50099 27.4697C9.44226 26.5166 8.53978 25.4255 8.53978 25.4255C8.53978 25.4255 7.77694 26.5963 7.83567 27.5494C7.84383 27.6808 7.86471 27.807 7.89212 27.9293C7.80957 27.8752 7.72637 27.8214 7.63469 27.7733C6.70382 27.2842 5.17227 27.3347 5.17227 27.3347C5.17227 27.3347 5.8493 28.5458 6.78016 29.0348C7.71103 29.5239 8.76524 29.4777 8.89281 29.2895C8.91108 29.263 8.91402 29.2204 8.9088 29.1699Z"
      fill="url(#paint6_linear_22160_170654)"
    />
    <path
      d="M11.9947 31.3751C11.9996 31.3476 11.9885 31.3105 11.9686 31.2699C12.1014 31.0234 12.1582 30.3371 11.8658 29.673C11.52 28.8877 10.5079 28.223 10.5079 28.223C10.5079 28.223 10.3167 29.4223 10.6629 30.2076C10.7105 30.3161 10.766 30.4164 10.825 30.5114C10.747 30.4891 10.6684 30.4672 10.5855 30.4524C9.74374 30.3023 8.62983 30.775 8.62983 30.775C8.62983 30.775 9.51372 31.6036 10.3555 31.7537C11.1973 31.9038 11.9605 31.5691 11.9947 31.3751Z"
      fill="black"
    />
    <path
      d="M11.9947 31.3751C11.9996 31.3476 11.9885 31.3105 11.9686 31.2699C12.1014 31.0234 12.1582 30.3371 11.8658 29.673C11.52 28.8877 10.5079 28.223 10.5079 28.223C10.5079 28.223 10.3167 29.4223 10.6629 30.2076C10.7105 30.3161 10.766 30.4164 10.825 30.5114C10.747 30.4891 10.6684 30.4672 10.5855 30.4524C9.74374 30.3023 8.62983 30.775 8.62983 30.775C8.62983 30.775 9.51372 31.6036 10.3555 31.7537C11.1973 31.9038 11.9605 31.5691 11.9947 31.3751Z"
      fill="url(#paint7_linear_22160_170654)"
    />
    <path
      d="M14.9446 32.1254C15.0007 31.8704 14.8604 31.244 14.3945 30.7314C13.8431 30.125 12.693 29.8044 12.693 29.8044C12.693 29.8044 12.8512 30.9231 13.4026 31.5298C13.4786 31.6134 13.5599 31.6881 13.6428 31.7569C13.5625 31.7579 13.4816 31.7596 13.3984 31.7688C12.5556 31.8605 11.6313 32.5796 11.6313 32.5796C11.6313 32.5796 12.7063 33.0808 13.5488 32.9887C14.3916 32.8969 15.0219 32.3941 14.9994 32.2122C14.9962 32.186 14.9749 32.1562 14.9446 32.1254Z"
      fill="black"
    />
    <path
      d="M14.9446 32.1254C15.0007 31.8704 14.8604 31.244 14.3945 30.7314C13.8431 30.125 12.693 29.8044 12.693 29.8044C12.693 29.8044 12.8512 30.9231 13.4026 31.5298C13.4786 31.6134 13.5599 31.6881 13.6428 31.7569C13.5625 31.7579 13.4816 31.7596 13.3984 31.7688C12.5556 31.8605 11.6313 32.5796 11.6313 32.5796C11.6313 32.5796 12.7063 33.0808 13.5488 32.9887C14.3916 32.8969 15.0219 32.3941 14.9994 32.2122C14.9962 32.186 14.9749 32.1562 14.9446 32.1254Z"
      fill="url(#paint8_linear_22160_170654)"
    />
    <path
      d="M29.7296 11.501C30.5946 11.736 31.4211 11.4712 31.515 11.2676C31.7069 11.1296 31.901 10.303 31.6188 9.47076C31.3222 8.59561 30.2996 7.78634 30.2996 7.78634C30.2996 7.78634 29.9812 9.05416 30.2778 9.92964C30.3137 10.0355 30.3561 10.1358 30.4024 10.2315C30.3058 10.1919 30.205 10.1565 30.0993 10.1276C29.2109 9.88638 27.9717 10.2869 27.9717 10.2869C27.9717 10.2869 28.8409 11.2597 29.7296 11.501Z"
      fill="black"
    />
    <path
      d="M29.7296 11.501C30.5946 11.736 31.4211 11.4712 31.515 11.2676C31.7069 11.1296 31.901 10.303 31.6188 9.47076C31.3222 8.59561 30.2996 7.78634 30.2996 7.78634C30.2996 7.78634 29.9812 9.05416 30.2778 9.92964C30.3137 10.0355 30.3561 10.1358 30.4024 10.2315C30.3058 10.1919 30.205 10.1565 30.0993 10.1276C29.2109 9.88638 27.9717 10.2869 27.9717 10.2869C27.9717 10.2869 28.8409 11.2597 29.7296 11.501Z"
      fill="url(#paint9_linear_22160_170654)"
    />
    <path
      d="M27.2304 8.8162C28.0653 9.35178 29.0301 9.33277 29.1525 9.14037C29.2748 8.94797 28.8856 8.06101 28.0506 7.52543C27.2157 6.98985 25.815 7.00001 25.815 7.00001C25.815 7.00001 26.3954 8.28062 27.2304 8.8162Z"
      fill="black"
    />
    <path
      d="M27.2304 8.8162C28.0653 9.35178 29.0301 9.33277 29.1525 9.14037C29.2748 8.94797 28.8856 8.06101 28.0506 7.52543C27.2157 6.98985 25.815 7.00001 25.815 7.00001C25.815 7.00001 26.3954 8.28062 27.2304 8.8162Z"
      fill="url(#paint10_linear_22160_170654)"
    />
    <path
      d="M30.1032 12.8537C30.1032 12.8537 30.8778 14.0783 31.8064 14.5053C32.7105 14.9213 33.6629 14.7826 33.8029 14.5775C34.0378 14.4614 34.4006 13.5925 34.243 12.6298C34.0773 11.6173 33.1056 10.5442 33.1056 10.5442C33.1056 10.5442 32.5265 11.8733 32.6922 12.8858C32.7121 13.0081 32.7405 13.1258 32.7738 13.2392C32.6753 13.1782 32.5712 13.1205 32.4609 13.0697C31.532 12.6426 30.1032 12.8537 30.1032 12.8537Z"
      fill="black"
    />
    <path
      d="M30.1032 12.8537C30.1032 12.8537 30.8778 14.0783 31.8064 14.5053C32.7105 14.9213 33.6629 14.7826 33.8029 14.5775C34.0378 14.4614 34.4006 13.5925 34.243 12.6298C34.0773 11.6173 33.1056 10.5442 33.1056 10.5442C33.1056 10.5442 32.5265 11.8733 32.6922 12.8858C32.7121 13.0081 32.7405 13.1258 32.7738 13.2392C32.6753 13.1782 32.5712 13.1205 32.4609 13.0697C31.532 12.6426 30.1032 12.8537 30.1032 12.8537Z"
      fill="url(#paint11_linear_22160_170654)"
    />
    <path
      d="M34.0544 16.4471C33.1226 15.6578 31.4122 15.4418 31.4122 15.4418C31.4122 15.4418 31.9134 17.0984 32.8456 17.8876C33.7529 18.6559 34.8923 18.8024 35.1184 18.6114C35.4254 18.5533 36.1188 17.6706 36.2441 16.5133C36.3756 15.2959 35.5997 13.7498 35.5997 13.7498C35.5997 13.7498 34.5112 15.0924 34.3794 16.3097C34.3634 16.4563 34.3582 16.6001 34.3601 16.7408C34.2662 16.6391 34.1647 16.5405 34.0544 16.4471Z"
      fill="black"
    />
    <path
      d="M34.0544 16.4471C33.1226 15.6578 31.4122 15.4418 31.4122 15.4418C31.4122 15.4418 31.9134 17.0984 32.8456 17.8876C33.7529 18.6559 34.8923 18.8024 35.1184 18.6114C35.4254 18.5533 36.1188 17.6706 36.2441 16.5133C36.3756 15.2959 35.5997 13.7498 35.5997 13.7498C35.5997 13.7498 34.5112 15.0924 34.3794 16.3097C34.3634 16.4563 34.3582 16.6001 34.3601 16.7408C34.2662 16.6391 34.1647 16.5405 34.0544 16.4471Z"
      fill="url(#paint12_linear_22160_170654)"
    />
    <path
      d="M36.9478 18.2479C36.9478 18.2479 35.4835 19.1243 34.9647 20.1902C34.9145 20.2935 34.8727 20.3971 34.8349 20.5006C34.7739 20.3607 34.7024 20.2204 34.6173 20.0824C33.9918 19.0686 32.4449 18.3295 32.4449 18.3295C32.4449 18.3295 32.3819 19.9962 33.0074 21.01C33.5343 21.8642 34.3761 22.3493 34.7726 22.3988C34.8036 22.4535 34.8375 22.4925 34.8737 22.5089C35.1269 22.6249 36.1429 22.033 36.662 20.9671C37.1808 19.9011 36.9478 18.2479 36.9478 18.2479Z"
      fill="black"
    />
    <path
      d="M36.9478 18.2479C36.9478 18.2479 35.4835 19.1243 34.9647 20.1902C34.9145 20.2935 34.8727 20.3971 34.8349 20.5006C34.7739 20.3607 34.7024 20.2204 34.6173 20.0824C33.9918 19.0686 32.4449 18.3295 32.4449 18.3295C32.4449 18.3295 32.3819 19.9962 33.0074 21.01C33.5343 21.8642 34.3761 22.3493 34.7726 22.3988C34.8036 22.4535 34.8375 22.4925 34.8737 22.5089C35.1269 22.6249 36.1429 22.033 36.662 20.9671C37.1808 19.9011 36.9478 18.2479 36.9478 18.2479Z"
      fill="url(#paint13_linear_22160_170654)"
    />
    <path
      d="M33.9605 24.4644C33.9543 24.3139 33.9389 24.1599 33.9105 24.0032C33.702 22.8524 32.5735 21.6482 32.5735 21.6482C32.5735 21.6482 31.9395 23.1746 32.1477 24.3254C32.3232 25.295 32.9089 26.0272 33.2466 26.2058C33.2554 26.2671 33.2723 26.3147 33.2988 26.3422C33.4854 26.5346 34.6 26.3255 35.4343 25.5093C36.2685 24.6932 36.633 23.0802 36.633 23.0802C36.633 23.0802 35.0182 23.4037 34.184 24.2199C34.1034 24.2989 34.03 24.3811 33.9605 24.4644Z"
      fill="black"
    />
    <path
      d="M33.9605 24.4644C33.9543 24.3139 33.9389 24.1599 33.9105 24.0032C33.702 22.8524 32.5735 21.6482 32.5735 21.6482C32.5735 21.6482 31.9395 23.1746 32.1477 24.3254C32.3232 25.295 32.9089 26.0272 33.2466 26.2058C33.2554 26.2671 33.2723 26.3147 33.2988 26.3422C33.4854 26.5346 34.6 26.3255 35.4343 25.5093C36.2685 24.6932 36.633 23.0802 36.633 23.0802C36.633 23.0802 35.0182 23.4037 34.184 24.2199C34.1034 24.2989 34.03 24.3811 33.9605 24.4644Z"
      fill="url(#paint14_linear_22160_170654)"
    />
    <path
      d="M32.3653 27.7736C32.2736 27.8218 32.1901 27.8755 32.1079 27.9296C32.1353 27.8073 32.1561 27.6812 32.1643 27.5497C32.223 26.5966 31.4602 25.4257 31.4602 25.4257C31.4602 25.4257 30.5577 26.5169 30.499 27.4701C30.4494 28.2757 30.8236 28.9667 31.0912 29.1702C31.086 29.2207 31.0892 29.2633 31.1072 29.2902C31.2347 29.4783 32.2889 29.5245 33.2198 29.0355C34.1507 28.5465 34.8277 27.3354 34.8277 27.3354C34.8277 27.3354 33.2962 27.2845 32.3653 27.7736Z"
      fill="black"
    />
    <path
      d="M32.3653 27.7736C32.2736 27.8218 32.1901 27.8755 32.1079 27.9296C32.1353 27.8073 32.1561 27.6812 32.1643 27.5497C32.223 26.5966 31.4602 25.4257 31.4602 25.4257C31.4602 25.4257 30.5577 26.5169 30.499 27.4701C30.4494 28.2757 30.8236 28.9667 31.0912 29.1702C31.086 29.2207 31.0892 29.2633 31.1072 29.2902C31.2347 29.4783 32.2889 29.5245 33.2198 29.0355C34.1507 28.5465 34.8277 27.3354 34.8277 27.3354C34.8277 27.3354 33.2962 27.2845 32.3653 27.7736Z"
      fill="url(#paint15_linear_22160_170654)"
    />
    <path
      d="M29.4148 30.4531C29.3319 30.4679 29.2533 30.4898 29.1753 30.5121C29.2343 30.4171 29.2898 30.3168 29.3374 30.2083C29.6833 29.4229 29.4924 28.2236 29.4924 28.2236C29.4924 28.2236 28.4803 28.888 28.1345 29.6737C27.8421 30.3374 27.8989 31.0241 28.0317 31.2706C28.0118 31.3116 28.0007 31.3483 28.0056 31.3758C28.0398 31.5698 28.803 31.9048 29.6448 31.7547C30.4866 31.6046 31.3705 30.776 31.3705 30.776C31.3705 30.776 30.2566 30.303 29.4148 30.4531Z"
      fill="black"
    />
    <path
      d="M29.4148 30.4531C29.3319 30.4679 29.2533 30.4898 29.1753 30.5121C29.2343 30.4171 29.2898 30.3168 29.3374 30.2083C29.6833 29.4229 29.4924 28.2236 29.4924 28.2236C29.4924 28.2236 28.4803 28.888 28.1345 29.6737C27.8421 30.3374 27.8989 31.0241 28.0317 31.2706C28.0118 31.3116 28.0007 31.3483 28.0056 31.3758C28.0398 31.5698 28.803 31.9048 29.6448 31.7547C30.4866 31.6046 31.3705 30.776 31.3705 30.776C31.3705 30.776 30.2566 30.303 29.4148 30.4531Z"
      fill="url(#paint16_linear_22160_170654)"
    />
    <path
      d="M26.3572 31.757C26.4401 31.6882 26.5214 31.6138 26.5974 31.5299C27.1488 30.9231 27.307 29.8045 27.307 29.8045C27.307 29.8045 26.1569 30.125 25.6055 30.7314C25.1396 31.244 24.999 31.8704 25.0554 32.1254C25.0251 32.1566 25.0038 32.1864 25.0006 32.2123C24.9781 32.3942 25.6084 32.897 26.4512 32.9888C27.294 33.0805 28.3687 32.5797 28.3687 32.5797C28.3687 32.5797 27.4441 31.8606 26.6016 31.7688C26.5184 31.7596 26.4378 31.758 26.3572 31.757Z"
      fill="black"
    />
    <path
      d="M26.3572 31.757C26.4401 31.6882 26.5214 31.6138 26.5974 31.5299C27.1488 30.9231 27.307 29.8045 27.307 29.8045C27.307 29.8045 26.1569 30.125 25.6055 30.7314C25.1396 31.244 24.999 31.8704 25.0554 32.1254C25.0251 32.1566 25.0038 32.1864 25.0006 32.2123C24.9781 32.3942 25.6084 32.897 26.4512 32.9888C27.294 33.0805 28.3687 32.5797 28.3687 32.5797C28.3687 32.5797 27.4441 31.8606 26.6016 31.7688C26.5184 31.7596 26.4378 31.758 26.3572 31.757Z"
      fill="url(#paint17_linear_22160_170654)"
    />
    <path
      d="M21.0276 19.12C22.4976 19.442 23.5756 20.408 23.5756 22.004C23.5756 24.048 21.9796 25.182 19.9636 25.182C18.2416 25.182 16.8556 24.398 16.2956 22.914L17.6816 22.116C17.9616 23.04 18.7596 23.614 19.8936 23.614C21.2096 23.614 21.9656 23.012 21.9656 22.004C21.9656 20.982 21.2096 20.394 19.8936 20.394H19.5436L18.8856 19.414L21.1256 16.712H16.6176V15.2H23.1276V16.572L21.0276 19.12Z"
      fill="url(#paint18_linear_22160_170654)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_22160_170654"
        x1="8.5749"
        y1="9.6391"
        x2="26.4872"
        y2="29.3198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_22160_170654"
        x1="8.5749"
        y1="9.6391"
        x2="26.4872"
        y2="29.3198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_22160_170654"
        x1="8.5749"
        y1="9.6391"
        x2="26.4872"
        y2="29.3198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_22160_170654"
        x1="8.5749"
        y1="9.6391"
        x2="26.4872"
        y2="29.3198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_22160_170654"
        x1="8.5749"
        y1="9.6391"
        x2="26.4872"
        y2="29.3198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_22160_170654"
        x1="8.5749"
        y1="9.6391"
        x2="26.4872"
        y2="29.3198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_22160_170654"
        x1="8.5749"
        y1="9.6391"
        x2="26.4872"
        y2="29.3198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_22160_170654"
        x1="8.5749"
        y1="9.6391"
        x2="26.4872"
        y2="29.3198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_22160_170654"
        x1="8.5749"
        y1="9.6391"
        x2="26.4872"
        y2="29.3198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_22160_170654"
        x1="13.2103"
        y1="9.63914"
        x2="31.1229"
        y2="29.3199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_22160_170654"
        x1="13.2103"
        y1="9.63914"
        x2="31.1229"
        y2="29.3199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_22160_170654"
        x1="13.2103"
        y1="9.63914"
        x2="31.1229"
        y2="29.3199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_22160_170654"
        x1="13.2103"
        y1="9.63914"
        x2="31.1229"
        y2="29.3199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_22160_170654"
        x1="13.2103"
        y1="9.63914"
        x2="31.1229"
        y2="29.3199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_22160_170654"
        x1="13.2103"
        y1="9.63914"
        x2="31.1229"
        y2="29.3199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_22160_170654"
        x1="13.2103"
        y1="9.63914"
        x2="31.1229"
        y2="29.3199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_22160_170654"
        x1="13.2103"
        y1="9.63914"
        x2="31.1229"
        y2="29.3199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_22160_170654"
        x1="13.2103"
        y1="9.63914"
        x2="31.1229"
        y2="29.3199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D2988B" />
        <stop offset="1" stop-color="#9B5448" />
      </linearGradient>
      <linearGradient id="paint18_linear_22160_170654" x1="20" y1="15" x2="20" y2="25" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FAFAFA" />
        <stop offset="1" stop-color="#FFD0C5" />
      </linearGradient>
    </defs>
  </svg>
)
