import { useEffect } from 'react'
import { InteractiveModal } from '@/ui/kit'
import { useActions } from '@/utils'
import { useAppSelector } from '@/utils'
import style from './style.module.scss'
import { okxAffiliateActions } from '@/redux/okxAffiliate/okxAffiliate.slice'

type Props = {
  name: string
}
export const LeaderList = (props: Props) => {
  const { isOfferPopup, userStatus } = useAppSelector(state => state.okxAffiliate)
  const { GetUserStatus, closeOfferPopup } = useActions(okxAffiliateActions)

  useEffect(() => {
    GetUserStatus()
  }, [])

  function closePopup() {
    closeOfferPopup()
  }

  return <div className={style.wrapper}>{props.name}</div>
}
