import {
  INotificationConfig,
  NotififcationPresets,
  SystemNotifiactionIcon,
} from '@/ui/molecules/SystemNotifications/notifications.type'
import { Trans } from '@lingui/react'
import styles from './style.module.scss'
import clsx from 'clsx'
import { IMetadata } from '@/backend/models/ProfileDTO'

const currentDateTimestamp = new Date().getTime()

export function getCashbackDelayAlert({
  isMobile,
  firstSesstionTrackedTimestamp,
}: {
  isMobile: boolean
  firstSesstionTrackedTimestamp: IMetadata['firstSesstionTrackedTimestamp']
}): INotificationConfig {
  const endDate = new Date('2024-09-05').setUTCHours(3, 0, 0, 0)
  const userTimestamp = firstSesstionTrackedTimestamp
    ? new Date(parseInt(firstSesstionTrackedTimestamp)).getTime()
    : new Date('2024-09-06').setUTCHours(3, 0, 0, 0)

  const preset: INotificationConfig = {
    id: NotififcationPresets.CashbackDelayAlert,
    icon: SystemNotifiactionIcon.Alert,
    heading: (
      <span className={clsx(isMobile && styles.mobile, styles.label)}>
        <Trans
          id={'core.systemAlert.cashbackDelayed.label'}
          message="Bybit technical work"
          components={{
            gradientText: <span className={styles.gradient} />,
          }}
        />
      </span>
    ),
    description: (
      <Trans
        id="core.systemAlert.cashbackDelayed.description"
        components={{
          colored: <span className={styles.colored}></span>,
          gradient: <span className={styles.gradientText}></span>,
        }}
      />
    ),
    shouldShown: userTimestamp > endDate,
    availableExchange: ['BYBIT_BROKER'],
    isClosable: false,
    link: {
      uri: `https://announcements.bybit.com/article/product-updates-introducing-inverse-derivatives-trading-to-unified-trading-account-uta--blte3dc1e58c8aefd04/?utm_campaign=website&utm_medium=email&utm_source=sendgrid.com`,
    },
  }

  return preset
}
