import { Address } from './Address'
import { Alert } from './Alert'
import { Apple } from './Apple'
import { Calendar } from './Calendar'
import { Check } from './Check'
import { Crown } from './Crown'
import { DoneCheckbox } from './DoneCheckbox'
import { Dot } from './Dot'
import { ExpiredFill } from './ExpiredFill'
import { Eye } from './Eye'
import { Google } from './Google'
import { GooglePlay } from './GooglePlay'
import { Key } from './Key'
import { Lens } from './Lens'
import { Lightning, LightningSquare } from './Lightning'
import { ListCheckbox } from './ListCheckbox'
import { Lock } from './Lock'
import { Logo } from './Logo'
import { LogoQR } from './LogoQR'
import { Mail } from './Mail'
import { Mark } from './Mark'
import { Mark2 } from './Mark2'
import { Ok } from './Ok'
import { Warning } from './Warning'
import { Okey } from './Okey'
import { Pending } from './Pending'
import { Person } from './Person'
import { Plus } from './Plus'
import { QRCode } from './QRCode'
import { Question } from './Question'
import { QuestionWithBorder } from './QuestionWithBorder'
import { ScanQRCode } from './ScanQRCode'
import { Sell } from './Sell'
import { Settings } from './Settings'
import { Shield } from './Shield'
import { ShieldEmpty } from './ShieldEmpty'
import { ShieldWhite } from './ShieldWhite'
import { Smiley, SmileyGrayscale } from './Smiley'
import { SuccessFill } from './SuccessFill'
import { Tiger } from './Tiger'
import { TigerColor } from './TigerColor'
import { TwoFA } from './TwoFA'
import { Unlock } from './Unlock'
import { VideoPlay } from './VideoPlay'
import { Windows } from './Windows'
import { Hourglass } from './Hourglass'
import { SortArrowDown } from './SortArrowDown'
import { SortArrowUp } from './SortArrowUp'
import { New } from './New'
import { HourglassTwo } from './HorglassTwo'
import { HourglassAccent } from './HourglassAccent'
import { Tag } from './Tag'
import { Description } from './Description'
import { EyeClose } from './EyeClose'
import { EyeOpen } from './EyeOpen'
import { Dots } from './Dots'
import { Offer } from './Offer'
import { Link } from './Link'
import { Notify } from './Notification'
import { MailNew } from './MailNew'
import { CloudDown } from './CloudDown'

export const OtherIcons = {
  Crown,
  Eye,
  EyeOpen,
  EyeClose,
  Logo,
  Question,
  Dot,
  Ok,
  Okey,
  Mark,
  Mark2,
  Plus,
  Check,
  Apple,
  GooglePlay,
  Google,
  QRCode,
  ScanQRCode,
  Key,
  LogoQR,
  QuestionWithBorder,
  Lock,
  Link,
  Unlock,
  Pending,
  Person,
  Tiger,
  TigerColor,
  Mail,
  MailNew,
  Windows,
  Lens,
  SuccessFill,
  ExpiredFill,
  Settings,
  Lightning,
  LightningSquare,
  ListCheckbox,
  DoneCheckbox,
  VideoPlay,
  Shield,
  ShieldEmpty,
  ShieldWhite,
  Smiley,
  SmileyGrayscale,
  Address,
  Warning,
  Alert,
  Calendar,
  CloudDown,
  Sell,
  TwoFA,
  Hourglass,
  HourglassTwo,
  HourglassAccent,
  SortArrowDown,
  SortArrowUp,
  New,
  Tag,
  Description,
  Dots,
  Offer,
  Notify,
}
